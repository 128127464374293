@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: 280 50% 20%;
  --card-background: 280 50% 95%;
  --text: 280 50% 95%;
  --card-text: 280 50% 20%;
}

body {
  background-color: hsl(var(--background));
  color: hsl(var(--text));
}